import React from 'react'
import { graphql } from 'gatsby'

import { DefaultLayout } from '../layouts/defaultlayout'
import { Carousel } from '../components'

import { Navigation } from './_navigation'


export const query = graphql`
  query {
    carousel: directus {
       ...ProjectsWithoutImagesFragment
    }
    logo: directus {
      ...LogoFragment
    }
  }
`

export default ({ data }: any) => {
  return (
    <DefaultLayout>
      <Carousel carouselData={data.carousel.projects} /> 
      <Navigation
        logo={data.logo.logo.logo.imageUrl}
      />
    </DefaultLayout>
  )
}
